import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const shouldSwitchToMobileView = window.innerWidth < 768;
      setIsMobileView(shouldSwitchToMobileView);

      // Optionally, close the menu when resizing to a desktop view
      if (!shouldSwitchToMobileView && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen]);

  return (
    <nav className="navbar">
      {/* Render based on viewport size */}
      {isMobileView ? (
        <div className="mobile-menu-icon">
          <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
            <li>
              <Link to="/home" onClick={handleMenuToggle}>HOME</Link>
            </li>
            <li>
              <Link to="/services" onClick={handleMenuToggle}>SERVICES</Link>
            </li>
            <li>
              <Link to="/clients" onClick={handleMenuToggle}>CLIENTS</Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleMenuToggle}>PROJECTS</Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleMenuToggle}>ABOUT</Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleMenuToggle}>CONTACT</Link>
            </li>
          </ul>
        </div>
      ) : (
        <div className="desktop-menu">
          <ul>
            <li>
              <Link to="/home">HOME</Link>
            </li>
            <li>
              <Link to="/services">SERVICES</Link>
            </li>
            <li>
              <Link to="/clients">CLIENTS</Link>
            </li>
            <li>
              <Link to="/contact">PROJECTS</Link>
            </li>
            <li>
              <Link to="/contact">ABOUT</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
