import React from 'react';
import ServicesList from '../Main/ServicesList';
import Footer from '../Main/Footer';

const ServicesPage = () => {
  return (
    <div>
      <ServicesList />
      <Footer />
    </div>
  );
};
  
export default ServicesPage ;