import React, { useEffect } from 'react';
import '../Main/css/AboutMe.css'; // Ensure this CSS file exists and is properly linked
import swiftImg1 from '../assets/swift-img1.jpeg';
import swiftImg2 from '../assets/swift-img2.jpg';
import swiftImg3 from '../assets/swift-img3.jpg';

const AboutMe = () => {

  useEffect(() => {
    const aboutMeSection = document.querySelector('.about-me-section');
    const images = document.querySelectorAll('.about-image');
    
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
          entry.target.style.transform = 'translateY(0)';
          observer.unobserve(entry.target); // Unobserve after the first intersection
        }
      });
    }, { threshold: 0.1 }); // Trigger when 10% of the section is in view
    
    observer.observe(aboutMeSection);
    
    // Observe each image for the fade-in effect
    images.forEach(image => {
      observer.observe(image);
    });
  }, []);

  return (
    <div className="about-me-section">
      <div className="about-me-content">
        <div className="about-me-text-left">
          <h1>Empowering artists to reach new heights with every track</h1>
        </div>
        <div className="about-me-text-right">
        <p>
          Our music production services are more than just creating tracks – we're a catalyst for musical innovation. With a passion for pushing boundaries and a commitment to excellence, we elevate your sound to new heights, crafting unforgettable auditory experiences that captivate audiences worldwide.
        </p>
          <button className="read-more-button">Explore More <span>&rarr;</span></button>
        </div>
      </div>
      <div className="about-me-images">
        <img src={swiftImg1} alt="Music Production Image 1" className="about-image"/>
        <img src={swiftImg2} alt="Music Production Image 2" className="about-image"/>
        <img src={swiftImg3} alt="Music Production Image 3" className="about-image"/>
      </div>
    </div>
  );
};

export default AboutMe;