// src/components/ArtistCard.js
import React from 'react';
import '../Main/css/ArtistsCard.css';

const ArtistCard = ({ name, image, role }) => {
  return (
    <div className="artist-card">
      <div className="artist-image-container">
        <img src={image} alt={name} className="artist-image" />
        <div className="artist-overlay">
          <div className="artist-role">{role}</div>
        </div>
      </div>
      <div className="artist-details">
        <div className="artist-name">{name}</div>
      </div>
    </div>
  );
};
  
  
  
  export default ArtistCard;
