import React, { useEffect, useState } from 'react';
import '../Main/css/ProjectPage.css';

const ProjectPage = () => {
  const [albumCovers, setAlbumCovers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientId = 'ad50e07ba7d84fc682b6f443bd421c95'; // Use your actual client ID
        const clientSecret = '923fce65419e4b80addd4da07081e9bb'; // Use your actual client secret

        const tokenResponse = await fetch('https://accounts.spotify.com/api/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret),
          },
          body: 'grant_type=client_credentials',
        });

        const tokenData = await tokenResponse.json();
        const accessToken = tokenData.access_token;

        const playlistResponse = await fetch('https://api.spotify.com/v1/playlists/20zsTL8edAuZkrmWXKHAbW/tracks?limit=10', {
          headers: {
            'Authorization': 'Bearer ' + accessToken,
          },
        });

        const playlistData = await playlistResponse.json();
        const covers = playlistData.items.map(item => item.track.album.images[0].url);
        setAlbumCovers(covers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="recent-work-wrap">
      <div className="recent-work mt-5">
        <h2 className="text-center">Recent Work</h2>
        <div className="divider-line"></div>
        <div className="grid-container">
          {albumCovers.length > 0 ? (
            albumCovers.slice(0, 7).map((coverUrl, index) => (
              <div key={index} className={`grid-item grid-item-${index + 1}`}>
                <img src={coverUrl} alt={`Album Cover ${index + 1}`} className="grid-image" />
              </div>
            ))
          ) : (
            <p className="loading-text">Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;