import React, { useEffect } from 'react';
import '../Main/css/ServiceList.css'; // Adjust the path according to your project structure

const services = [
  
  {
    id: 'mixing',
    title: 'Mixing',
    content: 'Mixing is the art of blending individual tracks within a recording to create a version of the song that sounds as good as possible. Our process involves adjusting levels, panning, and time-based audio effects (chorus, reverb, delay). The aim is to bring out the best in your recording, ensuring clarity, depth, and balance across all elements.',
    image: '/imgs/services/mixing.jpg'
  },
  {
    id: 'mastering',
    title: 'Mastering',
    content: 'Mastering is the final step in the music production process, which prepares the final mix for distribution. This involves balancing sonic elements of a stereo mix and optimizing playback across all systems and media formats. Our mastering process ensures your music sounds professional, with enhanced clarity, depth, and the right volume level, ready for streaming, CD, or vinyl.',
    image: '/imgs/services/mastering.jpg'
  },
  {
    id: 'dolbyAtmosMixing',
    title: 'Dolby Atmos Mixing',
    content: 'Dolby Atmos mixing takes your music to another dimension, allowing for an immersive audio experience that extends beyond traditional surround sound. By treating sounds as individual objects, we can place them in a 3D space around the listener. This technology is perfect for artists looking to push the boundaries of music production and offer fans a truly enveloping listening experience.',
    image: '/imgs/services/dolby.jpg'
  },
  {
    id: 'surroundSoundMixing',
    title: '5.1 Surround Sound Mixing',
    content: '5.1 Surround Sound Mixing provides a rich and immersive audio experience that places the listener at the heart of the music. Utilizing six discrete channels of audio, we meticulously craft a soundscape that envelops the listener, enhancing the emotional impact and depth of your music, perfect for films, video games, and high-definition music releases.',
    image: '/imgs/services/surround.jpg'
  },
  {
    id: 'foley',
    title: 'Foley',
    content: 'Foley in music production involves the addition of live-recorded sounds to enhance the auditory experience of the listener. Our foley artists create and record natural, everyday sounds to layer over your music, providing a more dynamic and realistic soundstage. This technique is ideal for adding unique textures and elements to your tracks, bringing them to life.',
    image: '/imgs/services/foley.jpg'
  },
  {
    id: 'adr',
    title: 'ADR',
    content: 'Automatic Dialogue Replacement (ADR) is not just for film. In music videos and multimedia projects, ADR can be used to enhance or replace vocal tracks, ensuring the highest quality of clarity and emotion is conveyed. Our ADR services are perfect for artists looking to perfect their vocal performances or add narrative elements to their music videos or projects.',
    image: '/imgs/services/adr.jpg'
  }
];

const CustomServices = () => {
  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('reveal');
        } else {
          entry.target.classList.remove('reveal');
        }
      });
    });

    const serviceCards = document.querySelectorAll('.custom-service-card');
    serviceCards.forEach(card => observer.observe(card));

    return () => {
      serviceCards.forEach(card => observer.unobserve(card));
    };
  }, []);
  return (
    <div className="custom-services-section">
      <div className="custom-services-intro">
      <h2>Experience Professional Audio Production Tailored to Your Needs</h2>
      <p>
        Our diverse range of audio services is designed to elevate your projects to the next level. Whether you're looking to fine-tune your tracks with expert <strong>Mixing</strong> and <strong>Mastering</strong>, immerse your audience in an all-encompassing sonic experience with <strong>Dolby Atmos</strong> and <strong>Surround Sound</strong> mixing, or enhance your production with bespoke <strong>Foley</strong> sound effects and precise <strong>ADR</strong> recording, our team of professionals is ready to turn your vision into reality. 
        From music production to film, we ensure that each sound tells your story with clarity, depth, and precision.
      </p>
      </div>
      <div className="custom-services-container">
        {services.map((service, index) => (
          <div key={service.id} className={`custom-service-card ${index % 2 === 0 ? 'custom-reverse' : ''}`}>
            <div className="custom-service-text">
              <h3>{service.title}</h3>
              <p>{service.content}</p>
            </div>
            <div className="custom-service-image-container">
              <img src={service.image} alt={service.title} className="custom-service-image" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomServices;