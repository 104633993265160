import React from 'react';
import './css/LandingPage.css';
import logo from '../assets/swift-logo-og.png';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleEnterClick = () => {
    navigate('/home');
  };

  return (
    <div className="landing-page">
      <div className="content">
        <img src={logo} alt="Logo" className="logo" />
        <button className="enter-button" onClick={handleEnterClick}>Enter Site</button>
      </div>
    </div>
  );
};

export default LandingPage;
