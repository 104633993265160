import React, {useEffect} from 'react';
import ClientCard from '../Main/ClientCard';
import '../Main/css/ClientsList.css';

const artists = [
    { name: 'C-Kan', image: 'https://www.billboard.com/wp-content/uploads/2020/09/c-kan-2020-billboard-1548-1601306801.jpg?w=942&h=623&crop=1' },
    { name: 'Melodico', image: 'https://viberate-upload.ams3.cdn.digitaloceanspaces.com/prod/entity/artist/melodico-m2O9q' },
    { name: 'Don Aero', image: 'https://popnable.com/images/singers/temp/don_aero_mexico_top_50_157.jpg' },
    { name: 'Dalia Haro', image: 'https://www.rosadistrito.com/wp-content/uploads/2021/09/daliaharo.jpg' },
    { name: 'Crooked Stilo', image: 'https://geo-media.beatsource.com/image_size/500x500/7/1/1/711e842f-ebda-44f9-8397-64c23f82bfde.jpg' },
    { name: 'Crooked Stilo', image: 'https://geo-media.beatsource.com/image_size/500x500/7/1/1/711e842f-ebda-44f9-8397-64c23f82bfde.jpg' },
    { name: 'Crooked Stilo', image: 'https://geo-media.beatsource.com/image_size/500x500/7/1/1/711e842f-ebda-44f9-8397-64c23f82bfde.jpg' },
    
    
];

const ClientsSection = () => {
    useEffect(() => { 
        // Scroll to the top when the component is mounted
        window.scrollTo(0, 0);
    }, []);
  return (
    <div className="clients-section-wrapper">
      <h1 className="background-title">ARTISTS THAT</h1>
      <h2 className="foreground-title">Trust Us</h2>
      <div className="clients-grid">
        {artists.map((artist) => (
          <ClientCard key={artist.name} name={artist.name} image={artist.image} />
        ))}
      </div>
    </div>
  );
};

export default ClientsSection;