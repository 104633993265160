import React from 'react';
import '../Main/css/Documentary.css'; // Adjust the path according to your project structure

const DocumentarySection = () => {
  return (
    <div className="documentary-section">
      <h2 className="documentary-title">Our Documentary</h2>
      <p className="documentary-description">
        Dive deep into the journey behind our music production. Discover the passion, the process, and the people that bring our sound to life. Watch our latest documentary and get an inside look at the creative magic that happens in our studio.
      </p>
      <div className="video-container">
        <iframe
          className="documentary-video"
          src="https://www.youtube.com/embed/sCn4DrK4qtc"
          title="Documentary Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default DocumentarySection;