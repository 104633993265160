import React from 'react';
import '../Main/css/Footer.css';

const FooterPage = () => {
  return (
    <div className="footer-container">
      <footer>
        <div className="footer-content">
          <div className="footer-column">
            <h3>About Us</h3>
            <p>At 2SWIFT Productions, we pride ourselves on our ability to seamlessly blend traditional styles with contemporary flair, creating dynamic soundscapes that resonate with diverse audiences.</p>
          </div>
          <div className="footer-column">
            <h3>Contact Us</h3>
            <p>Email: info@example.com</p>
            <p>Phone: +123-456-7890</p>
            <p>Address: 123 Main Street, City, Country</p>
          </div>
          <div className="footer-column">
            <h3>Follow Us</h3>
            <div className="social-icons">
            <a href="#facebook"><i className="fab fa-facebook-f"></i></a>
            <a href="#twitter"><i className="fab fa-twitter"></i></a>
            <a href="#instagram"><i className="fab fa-instagram"></i></a>
        </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 2Swift Productions. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default FooterPage;
