import React from 'react';
import ClientsList from '../Main/ClientsList';
import Footer from '../Main/Footer';

const ClientsPage = () => {
  return (
    <div>
      <ClientsList />
      <Footer />
    </div>
  );
};
  
export default ClientsPage ;